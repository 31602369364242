(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/helper/helpers/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/sport/helper/helpers/handlebars-helpers.js');

var isServerSide = typeof exports === 'object';
var componentHelper;

function registerHelpers(hbs, dateFns) {
  hbs.registerHelper('sport', function() {
    return componentHelper.apply(this, [
      arguments,
      isServerSide ? 'sport' : svs.sport
    ]);
  });

  hbs.registerHelper('gameDate', function(dateString) {
    var date = new Date(dateString);
    var formattedDate = '';

    if (dateFns.isValid(date)) {
      if (dateFns.isToday(date)) {
        formattedDate = 'idag';
      } else if (dateFns.isTomorrow(date)) {
        formattedDate = 'imorgon';
      } else if (dateFns.isYesterday(date)) {
        formattedDate = 'igår';
      } else {
        formattedDate = dateFns.format(date, 'dd/M', { locale: dateFns.locales.sv });
      }

      formattedDate += ' ' + dateFns.format(date, 'HH:mm', { locale: dateFns.locales.sv });
    }
    return formattedDate;
  });
}

if (isServerSide) {
  var trinidad = require('trinidad-core').core;
  componentHelper = trinidad.components.require('utils', 'component-helper').api;
  registerHelpers(require('hbs'), require('date-fns'));
} else {
  componentHelper = svs.utils.component_helper.helper;
  registerHelpers(Handlebars, dateFns);
}


 })(window);